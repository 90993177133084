(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

exports.renderSendIcon = function (node, fontColor) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("fill", fontColor || '#ffffff');
  iconSvg.setAttribute("viewBox", "0 0 20 18");
  iconSvg.style = "width: 0.5em; height: 0.5em; font-size: 3.6em;";
  iconPath.setAttribute("fill", fontColor || '#ffffff');
  iconPath.setAttribute("d", "M1.4 17.4L18.85 9.92C19.66 9.57 19.66 8.43 18.85 8.08L1.4 0.600002C0.74 0.310002 0.00999999 0.800002 0.00999999 1.51L0 5.5C0 6 0.37 7.05 0.87 7.11L6 9L0.87 10.88C0.37 10.95 0 12 0 12.5L0.00999999 16.49C0.00999999 17.2 0.74 17.69 1.4 17.4Z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderSendFile = function (node, fontColor) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("fill", fontColor || '#ffffff');
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 0.5em; height: 0.5em; font-size: 3.3em; transform: rotate(38deg); margin-top: 8px; cursor: pointer;";
  iconPath.setAttribute("fill", fontColor || '#ffffff');
  iconPath.setAttribute("d", "M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderSendClose = function (node, fontColor) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("fill", fontColor || '#ffffff');
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 0.5em; height: 0.5em; font-size: 3.2em;";
  iconPath.setAttribute("fill", fontColor || '#ffffff');
  iconPath.setAttribute("d", "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderSendEmoje = function (node) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("fill", "#fff");
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 0.5em; height: 0.5em; font-size: 3.3em;";
  iconPath.setAttribute("fill", "#fff");
  iconPath.setAttribute("d", "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderPlayVideo = function (node) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("viewBox", "0 0 20 20");
  iconSvg.style = "width: 0.4em; height: 0.4em; font-size: 3.3em;";
  iconPath.setAttribute("fill", "#FE355A");
  iconPath.setAttribute("d", "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 14.5V5.5L14 10L8 14.5Z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderFile = function (node) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("viewBox", "0 0 16 20");
  iconSvg.style = "width: 0.4em; height: 0.4em; font-size: 3.3em;";
  iconPath.setAttribute("fill", "#FF9B00");
  iconPath.setAttribute("d", "M4 14H12V16H4V14ZM4 10H12V12H4V10ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderDelete = function (node, fontColor) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 0.4em; height: 0.4em; font-size: 4.3em;";
  iconPath.setAttribute("fill", fontColor || "#FE355A");
  iconPath.setAttribute("d", "M9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderTyping = function (node, fontColor) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  iconSvg.setAttribute("viewBox", "0 0 24 6");
  iconSvg.setAttribute('class', 'leanbot_chat_typing');
  var circleOneTyping = window.document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circleOneTyping.setAttribute('class', 'leanbot-dot');
  circleOneTyping.setAttribute("cx", 3);
  circleOneTyping.setAttribute("cy", 3);
  circleOneTyping.setAttribute("r", 3);
  var circleTwoTyping = window.document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circleTwoTyping.setAttribute('class', 'leanbot-dot');
  circleTwoTyping.setAttribute("cx", 12);
  circleTwoTyping.setAttribute("cy", 3);
  circleTwoTyping.setAttribute("r", 3);
  var circleThreeTyping = window.document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circleThreeTyping.setAttribute('class', 'leanbot-dot');
  circleThreeTyping.setAttribute("cx", 21);
  circleThreeTyping.setAttribute("cy", 3);
  circleThreeTyping.setAttribute("r", 3);
  iconSvg.appendChild(circleOneTyping);
  iconSvg.appendChild(circleTwoTyping);
  iconSvg.appendChild(circleThreeTyping);
  return node.appendChild(iconSvg);
};

exports.renderArrowRight = function (node) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 24px; height: 24px;";
  iconPath.setAttribute("fill", "#585858");
  iconPath.setAttribute("d", "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

exports.renderArrowLeft = function (node) {
  var iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  var iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
  iconSvg.setAttribute("viewBox", "0 0 24 24");
  iconSvg.style = "width: 24px; height: 24px;";
  iconPath.setAttribute("fill", "#585858");
  iconPath.setAttribute("d", "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z");
  iconSvg.appendChild(iconPath);
  return node.appendChild(iconSvg);
};

},{}],2:[function(require,module,exports){
"use strict";

var socket = null; // ATENÇÃO!!
// Não faça commits com alterações nas duas linhas de baixo
// sujeito a quebrar a pipeline

var serverSocket = 'https://bot.leanbot.com.br';
var serverShared = 'https://api.leanbot.com.br';

var _require = require('./webchat'),
    initButton = _require.initButton,
    initWindow = _require.initWindow,
    exportDeleteFileClickEvent = _require.exportDeleteFileClickEvent,
    exportedFileInputChangeEvent = _require.exportedFileInputChangeEvent,
    exportedHasSelectedFile = _require.exportedHasSelectedFile;

var _require2 = require('./webchat/dialog'),
    openDialog = _require2.openDialog,
    closeDialog = _require2.closeDialog;

var _require3 = require('./socket'),
    initSocket = _require3.initSocket;

var _require4 = require('./requests'),
    request = _require4.request;

var _require5 = require('./webchat/message'),
    sendMessageEvents = _require5.sendMessageEvents,
    receiveEventMessage = _require5.receiveEventMessage;

var dependencies = [{
  type: 'script',
  typeHtml: 'text/javascript',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/3.0.4/socket.io.js'
}, {
  type: 'link',
  src: "https://webchat.leanbot.com.br/css/styles.min.css",
  typeHtml: 'stylesheet'
}];
/**
 * @function
 * @param  {{type: string, src: string, typeHtml: string}} data
 * @return {void}
 */

var initHeaderDependencies = function initHeaderDependencies(data) {
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement(data.type);

  if (data.type === 'script') {
    script.type = data.typeHtml;
    script.src = data.src;
  } else {
    script.type = 'text/css';
    script.href = data.src;
    script.rel = data.typeHtml;
  }

  head.appendChild(script);
};
/**
 * @function
 * @params {null}
 * @return {void}
 */


var eventListen = function eventListen() {
  var input = window.document.getElementById('leanbot_chat_msg');
  input.addEventListener('input', function (event) {
    if (!event.data) return;

    if (exportedHasSelectedFile()) {
      exportedFileInputChangeEvent();
    }
  });
  input.addEventListener('keyup', function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      window.document.getElementById('leanbot_enviar_msg').click();
      return;
    }

    if (exportedHasSelectedFile() && event.keyCode === 8) {
      event.preventDefault();
      exportDeleteFileClickEvent();
      return;
    }
  });
};

var connectSocket = function connectSocket(serverID, client) {
  socket = initSocket(socket, serverSocket, serverID, client);
  return socket;
};

var loadEvents = function loadEvents(serverID, client, componentsColor, fontColor) {
  return function (givedSocket) {
    sendMessageEvents(givedSocket, serverID, serverSocket, client);
    receiveEventMessage(givedSocket, componentsColor, fontColor, serverSocket, serverID, client);
  };
};
/**
 * @function
 * @param  {string} url
 * @param  {string} serverID
 * @return {void}
 */


window.WebChat = function (_ref) {
  var serverID = _ref.serverID,
      image = _ref.image,
      client = _ref.client;
  request("".concat(serverShared, "/api/webchat/serverId/").concat(serverID), 'GET', null, {
    'LeanBot-Client': client
  }).then(function (_ref2) {
    var _ref2$configurations = _ref2.configurations,
        componentsColor = _ref2$configurations.componentsColor,
        backgroundColor = _ref2$configurations.backgroundColor,
        displayName = _ref2$configurations.displayName,
        sendAttachments = _ref2$configurations.sendAttachments,
        fontColor = _ref2$configurations.fontColor,
        image = _ref2$configurations.image,
        imageTitle = _ref2$configurations.imageTitle;
    dependencies.map(initHeaderDependencies);
    setTimeout(function () {
      initButton(image, openDialog(socket, function () {
        return connectSocket(serverID, client);
      }, loadEvents(serverID, client, componentsColor, fontColor)));
      initWindow({
        backgroundColor: backgroundColor,
        componentsColor: componentsColor,
        displayName: displayName,
        sendAttachments: sendAttachments,
        fontColor: fontColor,
        imageTitle: imageTitle
      }, function () {
        return closeDialog(socket);
      });
      eventListen();
      window.document.getElementById('leanbot_botao_chat').style.display = 'block';
    }, 4000);
  })["catch"](function (err) {
    return console.danger('Not permission webchat channel!');
  });
};

},{"./requests":4,"./socket":5,"./webchat":9,"./webchat/dialog":8,"./webchat/message":10}],3:[function(require,module,exports){
"use strict";

/**
 * @function
 * @param  {any} data
 * @return {void}
 */
exports.persistenceLocalStorageSave = function (data) {
  return window.localStorage.setItem('webchat-session', JSON.stringify(data));
};
/**
 * @function
 * @params {null}
 * @return {Object}
 */


exports.persistenceLocalStorageGet = function () {
  return JSON.parse(window.localStorage.getItem('webchat-session'));
};

},{}],4:[function(require,module,exports){
"use strict";

/**
 * @function
 * @param url
 * @returns {Promise<Response>}
 */
exports.request = function (url) {
  var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
  var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var json = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  return fetch(url, {
    method: method,
    mode: 'cors',
    // pode ser cors ou basic(default)
    body: body,
    redirect: 'follow',
    headers: headers || new Headers({
      'Content-Type': 'application/json'
    })
  }).then(function (resp) {
    return json ? resp.json() : resp;
  });
};

},{}],5:[function(require,module,exports){
"use strict";

var _require = require('../persistence'),
    persistenceLocalStorageSave = _require.persistenceLocalStorageSave,
    persistenceLocalStorageGet = _require.persistenceLocalStorageGet;
/**
 * @param  {any} socket
 * @param  {string} url
 * @param  {string} serverID
 */


exports.initSocket = function (socket, url, serverID, client) {
  var chatSession = persistenceLocalStorageGet();

  if (!chatSession) {
    var key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    chatSession = key;
    persistenceLocalStorageSave(chatSession);
  }

  var query = null;
  if (url.indexOf('http') >= 0) query = "ws://".concat(url.replace('http://', ''));
  if (url.indexOf('https') >= 0) query = "wss://".concat(url.replace('https://', ''));
  socket = window.io.connect(query, {
    transports: ['websocket'],
    upgrade: false,
    query: "name=".concat(chatSession, "&serverID=").concat(serverID, "&client=").concat(client)
  });
  return socket;
};

},{"../persistence":3}],6:[function(require,module,exports){
"use strict";

var _require = require('../assets/icons'),
    renderArrowRight = _require.renderArrowRight,
    renderArrowLeft = _require.renderArrowLeft;

var uuid = 0;
/**
 * @function
 * @param  {any} buttons
 * @return {void}
 */

var drawButton = function drawButton(buttons, backgroundColor, fontColor) {
  var button = document.createElement('button');
  button.textContent = buttons.label.length > 20 ? "".concat(buttons.label.slice(0, 20), "...") : buttons.label;
  button.className = 'leanbot_btn_small';
  button.style.margin = '10px';
  button.style.overflow = 'hidden';
  button.style.padding = '0';
  button.style.textTransform = 'none';
  button.style.borderRadius = '20px'; // button.style.width = '100px'
  // button.style.flexShrink = '0'
  //

  button.style.background = backgroundColor;
  button.style.color = fontColor;
  button.style.width = "250px";
  button.setAttribute('data-front', buttons.label);
  button.setAttribute('data-backend', buttons.value);
  button.setAttribute('onclick', "receiveClick('".concat(buttons.value, "', '").concat(buttons.label, "')"));
  return button;
};
/**
 * @param  {any} socket
 * @return {{createButton: Function}}
 */


module.exports = function (socket) {
  /**
   * @param  {string} front
   * @param  {string} backend
   * @return {void}
   */
  window.receiveClick = function (front, backend) {
    window.sendMessageBack(backend, front, socket);
  };

  return {
    /**
    * @function
    * @param  {any} data
    * @param  {number} uid
    * @return {void}
    */
    createButton: function createButton(data, componentsColor, fontColor) {
      var div = document.createElement('div');
      var nameDiv = "leanbot_divButton_".concat(uuid++);
      div.id = nameDiv;
      div.style.cssText = 'width:100%;float:left;text-align:center;margin-bottom:20px;';
      window.document.getElementById('leanbot_div_chat_messages').appendChild(div);

      for (var x = 0; x < data.length; x++) {
        var button = drawButton(data[x], componentsColor, fontColor);
        window.document.getElementById(nameDiv).appendChild(button);
      }
    }
  };
};

},{"../assets/icons":1}],7:[function(require,module,exports){
"use strict";

var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
/**
 * @function
 * @param  {any} message
 * @return {any}
 */

var replaceMessageRegex = function replaceMessageRegex(message) {
  var msg = message.replace(new RegExp('\r?\n', 'g'), '<br>');
  return msg.replace(exp, "<a href='$1' target='_blank'>$1</a>");
};
/**
 * @function
 * @param  {any} data
 * @return {socketData: any, message: string}
 */


exports.typeMessage = function (data) {
  var message = replaceMessageRegex(data.message);
  if (message === '**oi**') return {
    socketData: null,
    message: message
  };
  if (data.origin && data.origin === 'client') return {
    socketData: null,
    message: message
  };
  return {
    socketData: data,
    message: message
  };
};

},{}],8:[function(require,module,exports){
"use strict";

var _require = require('./message'),
    welcomeMessage = _require.welcomeMessage;

var _require2 = require('../persistence'),
    persistenceLocalStorageGet = _require2.persistenceLocalStorageGet;
/**
 * @function
 * @param  {any} socket
 * @return {void}
 */
// componentsColor - header and bottom
// backgroundColor - caixa das mensagens


exports.openDialog = function (socket, connectSocket, loadEvents) {
  return function () {
    if (!socket || !socket.connected) {
      socket = connectSocket();
    }

    loadEvents(socket);
    window.document.getElementById('leanbot_chatbot_dialog').style.display = 'block';
    var chatSession = persistenceLocalStorageGet();
    setTimeout(function () {
      window.document.getElementById('leanbot_chat_msg').disabled = false;
      window.document.getElementById('leanbot_enviar_msg').disabled = false;
      window.document.getElementById('leanbot_chat_msg').addEventListener('click', function () {
        var chatMessages = document.getElementById('leanbot_div_chat_messages');
        chatMessages.scrollTop = chatMessages.scrollHeight;
      });
    }, 4000);
    setTimeout(function () {
      return welcomeMessage(socket, chatSession);
    }, 500);
    document.getElementById('leanbot_chatbot_dialog').focus();
  };
};
/**
 * @function
 * @param {null}
 * @return {void}
 */


exports.closeDialog = function (socket) {
  socket.disconnect();
  window.document.getElementById('leanbot_div_chat_messages').textContent = '';
  window.document.getElementById('leanbot_chatbot_dialog').style.display = 'none';
};

},{"../persistence":3,"./message":10}],9:[function(require,module,exports){
"use strict";

var imageDefault = 'https://res.cloudinary.com/dabcfsrx5/image/upload/v1631547594/webchat/EMlIRCh_bkbykx.png';

var _require = require('../assets/icons'),
    renderSendIcon = _require.renderSendIcon,
    renderSendFile = _require.renderSendFile,
    renderSendClose = _require.renderSendClose,
    renderDelete = _require.renderDelete;
/**
 * @function
 * @param  {string} image
 * @param  {function} openDialog
 * @return {void}
 */


var renderOpenDialog = function renderOpenDialog(image, openDialog) {
  var imageChat = window.document.createElement('img');
  if (image !== undefined && image !== null && image.length > 0) imageChat.src = "data:image/png;base64, ".concat(image);else imageChat.src = imageDefault;
  var chatBottom = window.document.createElement('div');
  chatBottom.className = 'leanbot_botao_chat';
  chatBottom.id = 'leanbot_botao_chat';
  chatBottom.style = 'display:none';
  chatBottom.onclick = openDialog;
  chatBottom.appendChild(imageChat);
  window.document.getElementsByTagName('body')[0].appendChild(chatBottom);
};
/**
 * @function
 * @params {null}
 * @return {void}
 */


var renderChatDialog = function renderChatDialog() {
  var chatPopup = window.document.createElement('div');
  chatPopup.id = 'leanbot_chatbot_dialog';
  chatPopup.className = 'leanbot_chat_popup';
  chatPopup.style = 'display:none';
  window.document.getElementsByTagName('body')[0].appendChild(chatPopup);
};
/**
 * @function
 * @param  {string} image
 * @param  {function} openDialog
 * @return {void}
 */


exports.initButton = function (image, openDialog) {
  renderOpenDialog(image, openDialog);
  renderChatDialog();
};
/**
 * @function
 * @param  {function} closeDialog
 * @return {void}
 */


var renderChatTitleContainer = function renderChatTitleContainer(componentsColor, displayName, closeDialog, fontColor, imageTitle) {
  var chatTituloContainer = window.document.createElement('div');
  chatTituloContainer.id = 'leanbot_titulo';
  chatTituloContainer.className = 'leanbot_chat_titulo_container';
  if (componentsColor !== undefined) chatTituloContainer.style = "background-color: ".concat(componentsColor, " !important;");
  var tituloTexto = window.document.createElement('div');
  tituloTexto.id = 'leanbot_titulo_texto';
  var contentTitle = window.document.createElement('h2');
  contentTitle.className = 'leanbot_chat_titulo';
  contentTitle.style = "color: ".concat(fontColor);
  if (displayName !== undefined) contentTitle.innerHTML = displayName;

  if (imageTitle && imageTitle !== undefined && imageTitle !== null && imageTitle.length > 0) {
    var imageTitleChat = window.document.createElement('img');
    imageTitleChat.src = "data:image/png;base64, ".concat(imageTitle);
    imageTitleChat.className = 'leanbot_icon_title';
    chatTituloContainer.appendChild(imageTitleChat);
  }

  tituloTexto.appendChild(contentTitle);
  var leanbot_div_fecha_chat = window.document.createElement('div');
  leanbot_div_fecha_chat.id = 'leanbot_div_fecha_chat';
  leanbot_div_fecha_chat.className = 'leanbot_fecha_chat';
  leanbot_div_fecha_chat.onclick = closeDialog;
  renderSendClose(leanbot_div_fecha_chat, fontColor);
  chatTituloContainer.appendChild(tituloTexto);
  chatTituloContainer.appendChild(leanbot_div_fecha_chat);
  window.document.getElementById('leanbot_chatbot_dialog').appendChild(chatTituloContainer);
};
/**
 * @function
 * @params {null}
 * @return {HtmlElement}
 */


var renderSendBoxChatBotContainer = function renderSendBoxChatBotContainer(fontColor) {
  var sendBoxContainer = window.document.createElement('div');
  sendBoxContainer.className = 'leanbot_send_box_chatbot_container';
  var buttonSend = window.document.createElement('button');
  buttonSend.id = 'leanbot_enviar_msg';
  buttonSend.className = 'leanbot_botao_enviar';
  renderSendIcon(buttonSend, fontColor);
  sendBoxContainer.appendChild(buttonSend);
  return sendBoxContainer;
};
/**
 * @function
 * @params {null}
 * @return {void}
 */


var renderChatBodyContainer = function renderChatBodyContainer(backgroundColor) {
  var chatBodyContainer = window.document.createElement('div');
  chatBodyContainer.className = 'leanbot_chat_bodyt_container';
  if (backgroundColor !== undefined) chatBodyContainer.style = "background-color: ".concat(backgroundColor, " !important;");
  var divChatMessage = window.document.createElement('div');
  divChatMessage.id = 'leanbot_div_chat_messages';
  divChatMessage.className = 'leanbot_div_chat_messages';
  chatBodyContainer.appendChild(divChatMessage);
  window.document.getElementById('leanbot_chatbot_dialog').appendChild(chatBodyContainer);
};

var removeFileComponents = function removeFileComponents() {
  var fileDelete = window.document.getElementById('leanbot_choosed_file_delete');
  if (fileDelete) fileDelete.remove();
};

var deleteFileClickEvent = function deleteFileClickEvent(_) {
  removeFileComponents();
  var msgInputFile = window.document.getElementById('leanbot_file_input');
  msgInputFile.value = '';
  var msgInput = window.document.getElementById('leanbot_chat_msg');
  msgInput.value = '';
  msgInput.className = 'leanbot_campo_texto';
};

exports.exportDeleteFileClickEvent = function () {
  return deleteFileClickEvent(null);
};

exports.exportedFileInputChangeEvent = function () {
  return fileInputChangeEvent(null);
};

var hasSelectedFile = function hasSelectedFile() {
  var fileInput = window.document.getElementById('leanbot_file_input');
  var files = fileInput ? fileInput.files : [];
  return files.length > 0;
};

exports.exportedHasSelectedFile = function () {
  return hasSelectedFile();
};

var fileInputChangeEvent = function fileInputChangeEvent(event) {
  if (!hasSelectedFile()) return;
  removeFileComponents();
  var msgInput = window.document.getElementById('leanbot_chat_msg');
  var fileInput = window.document.getElementById('leanbot_file_input');
  msgInput.value = '';
  var deleteFileDiv = window.document.createElement('div');
  deleteFileDiv.id = 'leanbot_choosed_file_delete';
  deleteFileDiv.style = 'width: fit-content;float: left;cursor: pointer;position: fixed;margin-top: 10px;margin-right: 4px;';
  renderDelete(deleteFileDiv);
  deleteFileDiv.addEventListener('click', deleteFileClickEvent);
  msgInput.value = "".concat(fileInput.files[0].name);
  msgInput.className += ' leanbot_campo_texto__disabled';
  var boxChatbotContainer = window.document.getElementById('leanbot_box_chatbot_container');
  boxChatbotContainer.appendChild(deleteFileDiv);
};

var archiveFieldControl = function archiveFieldControl(chatFieldControl, sendAttachments, fontColor) {
  if (!sendAttachments) return null;
  /*
    - archive
   */

  var archiveFieldControl = window.document.createElement('div');
  archiveFieldControl.className = 'leanbot_archive_chatbot_container';
  var fileInput = window.document.createElement('input');
  fileInput.type = 'file';
  fileInput.id = 'leanbot_file_input';
  fileInput.addEventListener('change', fileInputChangeEvent);
  var labelControl = window.document.createElement('label');
  labelControl.setAttribute('for', 'leanbot_file_input');
  renderSendFile(labelControl, fontColor);
  archiveFieldControl.appendChild(fileInput);
  archiveFieldControl.appendChild(labelControl);
  chatFieldControl.appendChild(archiveFieldControl);
};
/**
 * @function
 * @params {null}
 * @return {void}
 */


var renderChatFieldControl = function renderChatFieldControl(componentsColor) {
  var sendAttachments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var fontColor = arguments.length > 2 ? arguments[2] : undefined;
  var chatFieldControl = window.document.createElement('div');
  chatFieldControl.id = 'leanbot_chat_field_control';
  chatFieldControl.className = 'leanbot_chat_field_control';
  chatFieldControl.style = "background-color: ".concat(componentsColor, " !important"); // archive field control

  archiveFieldControl(chatFieldControl, sendAttachments, fontColor);
  var boxChatbotContainer = window.document.createElement('div');
  boxChatbotContainer.id = 'leanbot_box_chatbot_container';
  boxChatbotContainer.className = 'leanbot_box_chatbot_container';
  var campoTexto = window.document.createElement('textarea');
  campoTexto.id = 'leanbot_chat_msg';
  campoTexto.className = 'leanbot_campo_texto';
  campoTexto.rows = '1';
  campoTexto.placeholder = 'Digite uma mensagem';
  campoTexto.addEventListener('keydown', function (e) {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
    }
  });
  var chatBotContainerSend = renderSendBoxChatBotContainer(fontColor);
  boxChatbotContainer.appendChild(campoTexto);
  chatFieldControl.appendChild(boxChatbotContainer);
  chatFieldControl.appendChild(chatBotContainerSend);
  window.document.getElementById('leanbot_chatbot_dialog').appendChild(chatFieldControl);
};
/**
 * @function
 * @param  {Function} closeDialog
 * @return {void}
 */


exports.initWindow = function (_ref, closeDialog) {
  var backgroundColor = _ref.backgroundColor,
      displayName = _ref.displayName,
      componentsColor = _ref.componentsColor,
      sendAttachments = _ref.sendAttachments,
      fontColor = _ref.fontColor,
      imageTitle = _ref.imageTitle;
  renderChatTitleContainer(componentsColor, displayName, closeDialog, fontColor, imageTitle);
  renderChatBodyContainer(backgroundColor);
  renderSendBoxChatBotContainer();
  renderChatFieldControl(componentsColor, sendAttachments, fontColor);
};

},{"../assets/icons":1}],10:[function(require,module,exports){
"use strict";

var _require = require('.'),
    exportDeleteFileClickEvent = _require.exportDeleteFileClickEvent;

var _require2 = require('../persistence'),
    persistenceLocalStorageGet = _require2.persistenceLocalStorageGet;

var _require3 = require('../requests'),
    request = _require3.request;

var _require4 = require('../types/message'),
    typeMessage = _require4.typeMessage;

var _require5 = require('../assets/icons'),
    renderPlayVideo = _require5.renderPlayVideo,
    renderFile = _require5.renderFile,
    renderTyping = _require5.renderTyping;

var contractMessage = {
  origin: 'client',
  nome_usuario: 'Usuário',
  message: undefined,
  chatSession: undefined
};
/**
 * @function
 * @param  {string | Object} str
 * @return {boolean}
 */

var isJson = function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}; // Send the message to the backend(nlp)

/**
 * @function
 * @param  {string} msnBack
 * @param  {string} msnFront
 * @param  {any} socket
 * @return {void}
 */


window.sendMessageBack = function (msnBack, msnFront, socket) {
  contractMessage.message = msnFront;
  contractMessage.origin = 'back';
  socket.emit('sendMessage', contractMessage);
  renderMessageFront(msnBack);
};

var RenderBalao = function RenderBalao(msg, currentTime, className) {
  var messageObject = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var serverID = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var serverSocket = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
  var clientName = arguments.length > 6 ? arguments[6] : undefined;
  var balaoCliente = window.document.createElement('div');
  balaoCliente.className = "leanbot_balao ".concat(className === 'leanbot_atendente' ? '' : 'leanbot_cliente_balao');

  if (messageObject && messageObject.type === 'file') {
    var fileDiv = window.document.createElement('div');
    fileDiv.style = 'width: fit-content; float: left; margin-right: 5px;';
    renderFile(fileDiv);
    var attachmentElement = window.document.createElement('div');
    attachmentElement.innerHTML = msg;
    attachmentElement.style = 'width: fit-content; float: left; margin-right: 5px; font-size: 0.9em; font-weight: bold; margin-top: 4px;';
    var messageDiv = window.document.createElement('div');
    messageDiv.style = 'cursor: pointer';
    messageDiv.appendChild(fileDiv);
    messageDiv.appendChild(attachmentElement);

    var read = function read(reader, buffer, callback) {
      reader.read().then(function (_ref) {
        var done = _ref.done,
            value = _ref.value;
        buffer = buffer.concat(value);

        if (!done) {
          read(reader, buffer, callback);
        } else {
          callback(buffer);
        }
      });
    };

    var responseTratment = function responseTratment(response) {
      var buffer = [];
      var reader = response.body.getReader();
      read(reader, buffer, function (buffer) {
        var blob = new Blob(buffer, {
          type: messageObject.mimetype
        });
        var deepLink = document.createElement('a');
        deepLink.href = window.URL.createObjectURL(blob);
        deepLink.setAttribute('download', messageObject.filename);
        deepLink.click();
      });
    };

    var headers = new Headers();
    headers.append('LeanBot-ChatSession', persistenceLocalStorageGet());
    headers.append('LeanBot-ServerId', serverID);
    headers.append('LeanBot-Client', clientName);

    if (messageObject.origin === 'tranship') {
      messageDiv.addEventListener('click', function (event) {
        event.preventDefault();
        request("".concat(serverSocket, "/api/webchat/tranship/file/").concat(messageObject.id), 'GET', null, headers, false).then(responseTratment);
      });
    } else {
      messageDiv.addEventListener('click', function (event) {
        event.preventDefault();
        request("".concat(serverSocket, "/api/webchat/client/file/").concat(messageObject.filename), 'GET', null, headers, false).then(responseTratment);
      });
    }

    balaoCliente.appendChild(messageDiv);
  } else {
    balaoCliente.innerHTML = msg;
  }

  return renderBasic(className, balaoCliente);
};
/**
 * @function
 * @param  {string} msg
 * @return {void}
 */


var renderMessageFront = function renderMessageFront(msg) {
  var messageObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var serverID = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var serverSocket = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var clientName = arguments.length > 4 ? arguments[4] : undefined;
  var currentTime = new Date().toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
  window.document.getElementById('leanbot_div_chat_messages').appendChild(RenderBalao(msg, currentTime, 'leanbot_cliente', messageObject, serverID, serverSocket, clientName));
  scroll();
};
/**
 * @function
 * @params {null}
 * @return {void}
 */


var scroll = function scroll() {
  var chatMessages = document.getElementById('leanbot_div_chat_messages');
  chatMessages.scrollTop = chatMessages.scrollHeight;
};
/**
 * @function
 * @param  {any} socket
 * @return {void}
 */


exports.sendMessageEvents = function (socket, serverID, serverSocket, clientName) {
  var input = window.document.getElementById('leanbot_enviar_msg');

  input.onclick = function (event) {
    event.preventDefault();
    var fileInput = window.document.getElementById('leanbot_file_input');
    var files = fileInput ? fileInput.files : [];

    if (files.length > 0) {
      window.document.getElementById('leanbot_enviar_msg').className += ' leanbot_loader';
      var formData = new FormData();
      formData.append('file', files[0], files[0].name);
      var headers = new Headers();
      headers.append('LeanBot-ChatSession', persistenceLocalStorageGet());
      headers.append('LeanBot-ServerId', serverID);
      headers.append('LeanBot-Client', clientName);
      request("".concat(serverSocket, "/api/webchat/post-file"), 'POST', formData, headers).then(function (_ref2) {
        var filename = _ref2.filename,
            mimetype = _ref2.mimetype;
        renderMessageFront(files[0].name, {
          type: 'file',
          filename: filename,
          mimetype: mimetype
        }, serverID, serverSocket, clientName);
        window.document.getElementById('leanbot_enviar_msg').className = 'leanbot_botao_enviar';
        exportDeleteFileClickEvent();
      });
      return;
    }

    var msg = window.document.getElementById('leanbot_chat_msg').value;
    if (msg.length === 0) return;
    contractMessage.message = msg;
    renderMessageFront(msg);
    socket.emit('sendMessage', contractMessage);
    window.document.getElementById('leanbot_chat_msg').value = '';
  };
};

var renderTypingMessage = function renderTypingMessage() {
  var divChatTyping = window.document.createElement('div');
  divChatTyping.id = 'div_chat_typing';
  renderTyping(divChatTyping);
  return divChatTyping;
};

var renderImage = function renderImage(image, caption) {
  var divChat = window.document.createElement('div');
  divChat.className = 'leanbot_balao';
  var divChatDocument = window.document.createElement('div');
  divChatDocument.style = 'display: flex; align-items: center; display: contents;';
  var documentChatA = window.document.createElement('a');
  documentChatA.href = image;
  documentChatA.target = '_blank';
  documentChatA.style = 'text-decoration: none;';
  var documentChatSpan = window.document.createElement('span');
  documentChatSpan.innerHTML = caption;
  documentChatSpan.style = 'margin-left: 3px;';
  var imageChat = window.document.createElement('img');
  imageChat.className = 'leanbot_chat_image';
  imageChat.src = image;
  imageChat.alt = 'image';
  divChatDocument.appendChild(imageChat);
  divChatDocument.appendChild(documentChatSpan);
  documentChatA.appendChild(divChatDocument);
  divChat.appendChild(documentChatA);
  return renderBasic('leanbot_atendente', divChat);
};

var renderDocument = function renderDocument(document, caption) {
  var divChat = window.document.createElement('div');
  divChat.className = 'leanbot_balao';
  var divChatDocument = window.document.createElement('div');
  divChatDocument.style = 'display: flex; align-items: center;';
  var documentChatA = window.document.createElement('a');
  documentChatA.href = document;
  documentChatA.target = '_blank';
  documentChatA.style = 'text-decoration: none;';

  if (caption) {
    var documentChatSpan = window.document.createElement('span');
    documentChatSpan.innerHTML = caption;
    documentChatSpan.style = 'margin-left: 3px;';
    renderFile(divChatDocument);
    divChatDocument.appendChild(documentChatSpan);
  }

  documentChatA.appendChild(divChatDocument);
  var ifrm = window.document.createElement("iframe");
  ifrm.setAttribute("src", document);
  ifrm.setAttribute("frameborder", "0");
  ifrm.style = 'height: 250px;';
  ifrm.title = caption;
  divChat.appendChild(ifrm);
  divChat.appendChild(documentChatA);
  return renderBasic('leanbot_atendente', divChat);
};

var renderVideo = function renderVideo(document, caption) {
  var divChat = window.document.createElement('div');
  divChat.className = 'leanbot_balao';
  var divChatDocument = window.document.createElement('div');
  divChatDocument.style = 'display: flex; align-items: center;';
  var documentChatA = window.document.createElement('a');
  documentChatA.href = document;
  documentChatA.target = '_blank';
  documentChatA.style = 'text-decoration: none;';

  if (caption) {
    var documentChatSpan = window.document.createElement('span');
    documentChatSpan.innerHTML = caption;
    documentChatSpan.style = 'margin-left: 3px;';
    renderPlayVideo(divChatDocument);
    divChatDocument.appendChild(documentChatSpan);
  }

  documentChatA.appendChild(divChatDocument);
  var ifrm = window.document.createElement("iframe");
  var videoURL = document;
  var youtubeVideo = document.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/);

  if (youtubeVideo && youtubeVideo.length > 0) {
    videoURL = "https://www.youtube.com/embed/".concat(youtubeVideo[1]);
  }

  var vimeoVideo = document.match(/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/);

  if (vimeoVideo && vimeoVideo.length > 3) {
    videoURL = "https://player.vimeo.com/video/".concat(vimeoVideo[4], "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media");
  }

  ifrm.setAttribute("src", videoURL);
  ifrm.setAttribute("frameborder", "0");
  ifrm.title = caption;
  divChat.appendChild(ifrm);
  divChat.appendChild(documentChatA);
  return renderBasic('leanbot_atendente', divChat);
};

var smallFileName = function smallFileName(filename) {
  if (filename.length > 20) {
    var extStartIndex = filename.indexOf('.');
    var endStr = extStartIndex > -1 ? filename.substring(extStartIndex - 4) : '';
    var startStr = filename.substring(0, 8) + '...';
    filename = startStr + endStr;
  }

  return filename;
};

var renderBasic = function renderBasic(className, node) {
  var divChatMain = window.document.createElement('div');
  divChatMain.className = className;
  var divChatImg = window.document.createElement('div');
  divChatImg.style = 'display: flex; align-items: center;'; // const imageImgChat = window.document.createElement('img')
  // imageImgChat.style = 'width: 40px; height: 40px; margin-right: 5px;'
  // imageImgChat.src = 'https://res.cloudinary.com/dabcfsrx5/image/upload/v1631547594/webchat/EMlIRCh_bkbykx.png'
  // imageImgChat.alt = 'bot'

  var chatTime = window.document.createElement('div');

  if (className === 'leanbot_atendente') {
    chatTime.className = 'leanbot_chat_time leanbot_chat_time_atendente';
  } else {
    chatTime.className = 'leanbot_chat_time leanbot_chat_time_client';
  }

  chatTime.innerHTML = chatTimeForm(); // if (className === 'leanbot_atendente') {
  //   divChatImg.appendChild(imageImgChat)
  // }

  divChatImg.appendChild(node);
  divChatMain.appendChild(divChatImg);
  divChatMain.appendChild(chatTime);
  return divChatMain;
};

var chatTimeForm = function chatTimeForm() {
  var date = new Date();
  return "".concat(date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit'
  }), " | ").concat(date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  }));
};
/**
 * @function
 * @param {any} socket
 * @param  {any} data
 * @return {void}
 */


var renderMessage = function renderMessage(socket, componentsColor, fontColor) {
  var serverSocket = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var serverID = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var clientName = arguments.length > 5 ? arguments[5] : undefined;
  return function (data) {
    var _require6 = require('../types/button')(socket),
        createButton = _require6.createButton;

    var currentTime = new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
    var typingElement = window.document.getElementsByClassName('leanbot_chat_typing');
    if (typingElement.length > 0) typingElement[0].remove();

    if (data.origin === 'tranship') {
      var msg = data.message.type === 'text' ? data.message.message : smallFileName(data.message.filename);
      data.message.origin = 'tranship';
      window.document.getElementById('leanbot_div_chat_messages').appendChild(RenderBalao(msg, currentTime, 'leanbot_atendente', data.message, serverID, serverSocket, clientName));
      scroll();
      return null;
    }

    if (data.type === 'silent' || data.image === 'silent') {
      var typing = window.document.getElementById('div_chat_typing');
      if (typing) typing.remove();
      scroll();
      return null;
    }

    if (data.type === 'typing' || data.image === 'typing') {
      window.document.getElementById('leanbot_div_chat_messages').appendChild(renderTypingMessage());
      scroll();
      return null;
    }

    if (data.type === 'image') {
      window.document.getElementById('leanbot_div_chat_messages').appendChild(renderImage(data.src, data.caption));
      setTimeout(scroll, 1000);
      return null;
    }

    if (data.type === 'document') {
      window.document.getElementById('leanbot_div_chat_messages').appendChild(renderDocument(data.src.src, data.src.caption));
      setTimeout(scroll, 1000);
      return null;
    }

    if (data.type === 'video') {
      window.document.getElementById('leanbot_div_chat_messages').appendChild(renderVideo(data.src.src, data.src.caption));
      setTimeout(scroll, 1000);
      return null;
    }

    if (data.type === 'simple_button' && data.buttons !== undefined && Array.isArray(data.buttons)) {
      window.document.getElementById('leanbot_div_chat_messages').appendChild(RenderBalao(data.title, currentTime, 'leanbot_atendente'));
      createButton(data.buttons, componentsColor, fontColor);
      scroll();
      return null;
    }

    if (data.message !== undefined) {
      var _typeMessage = typeMessage(data),
          message = _typeMessage.message;

      window.document.getElementById('leanbot_div_chat_messages').appendChild(RenderBalao(message, currentTime, 'leanbot_atendente'));
      scroll();
    }
  };
};
/**
 * @param  {any} socket
 * @return {void}
 */


exports.receiveEventMessage = function (socket, componentsColor, fontColor) {
  var serverSocket = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var serverID = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var clientName = arguments.length > 5 ? arguments[5] : undefined;
  socket.on('receivedMessage', renderMessage(socket, componentsColor, fontColor, serverSocket, serverID, clientName));
};
/**
 * @function
 * @param name
 * @param url
 * @returns {string|null}
 */


function getParameterByName(name) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

var tag = 'state';
/**
 * @param  {any} socket
 * @param  {string} chatSession
 * @return {void}
 */

exports.welcomeMessage = function (socket, chatSession) {
  if (!socket) return null;
  window.document.getElementById('leanbot_div_chat_messages').appendChild(renderTypingMessage());
  contractMessage.message = 'oi';
  contractMessage.contexts = {
    tag_mkt: getParameterByName(tag)
  };
  contractMessage.chatSession = chatSession;
  socket.emit('sendMessage', contractMessage);
};

},{".":9,"../assets/icons":1,"../persistence":3,"../requests":4,"../types/button":6,"../types/message":7}]},{},[2]);
